class {
    onClick(name) {
        this.emit("controlclick", {name: name});
        this.emit(name.toLowerCase());
    }
}

ui-linear-layout
    for|control| of=input.controls||[]
        @element
            ui-button rounded title=control.title||control.name icon=control.icon||null on-click("onClick", control.name)